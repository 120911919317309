<template>
    <div v-if="isMobile" class="container has-space">
        <MobileHeaderBack></MobileHeaderBack>
        <div class="mobile-account__container">
          <h2  class="signup-form__title">{{$t(title)}}</h2>
           <div class="change-password__form-group">
                <!--current password -->
                <div v-if="!isNeverSetPassword" class="form-group">
                    <label class="form-label">{{$t('CURRENT_PASSWORD')}}</label>
                    <div class="form-group__container">
                        <input class="form-control" :type="currentPassword.showPassword ? 'text' : 'password'" v-model="currentPassword.inputValue" minlength="8" maxlength="20" v-on:keyup="currentInputPasswordEvent" :placeholder="$t('ENTER_CURRENT_PASSWORD')">
                        <div class="display-flex-center form-icon pointer" @click="currentPassword.showPassword = !currentPassword.showPassword">
                            <img v-if="currentPassword.showPassword" src="../../../static/images/icons/icon_close_pwd.png">
                            <img v-else src="../../../static/images/icons/icon_show_pwd.png">
                        </div>
                    </div>
                    <div class="form-tips">
                        <div v-if="currentPassword.isError" class="error">{{$t('FORGET_PASSWORD_ERR_MSG')}}</div>
                    </div>
                </div>
                <!--new password -->
                <div class="form-group">
                    <label class="form-label">{{$t('NEW_PASSWORD')}}</label>
                    <div class="form-group__container">
                        <input class="form-control" :type="newPassword.showPassword ? 'text' : 'password'" v-model="newPassword.inputValue" minlength="8" maxlength="20" v-on:keyup="newInputPasswordEvent" :placeholder="$t('ENTER_NEW_PASSWORD')">
                        <div class="display-flex-center form-icon pointer" @click="newPassword.showPassword = !newPassword.showPassword">
                            <img v-if="newPassword.showPassword" src="../../../static/images/icons/icon_close_pwd.png">
                            <img v-else src="../../../static/images/icons/icon_show_pwd.png">
                        </div>
                    </div>
                    <div class="form-tips">
                        <div v-if="newPassword.isError" class="error">{{$t('FORGET_PASSWORD_ERR_MSG')}}</div>
                        <div v-else >{{$t('FORGET_PASSWORD_MSG')}}</div>
                    </div>
                </div>
                <!-- confirm password -->
                <div class="form-group">
                        <label class="form-label">{{$t('CONFIRM_PASSWORD')}}</label>
                        <div class="form-group__container">
                            <input class="form-control" :type="confirmPassword.showPassword ? 'text' : 'password'" v-model="confirmPassword.inputValue" minlength="8" maxlength="20" v-on:keyup="confirmInputPasswordEvent" :placeholder="$t('RE_ENTER_NEW_PASSWORD')">
                            <div class="display-flex-center form-icon pointer" @click="confirmPassword.showPassword = !confirmPassword.showPassword">
                                <img v-if="confirmPassword.showPassword" src="../../../static/images/icons/icon_close_pwd.png">
                                <img v-else src="../../../static/images/icons/icon_show_pwd.png">
                            </div>
                        </div>
                        <div class="form-tips">
                            <div v-if="confirmPassword.isError" class="error">{{$t('RETYPE_YOUR_PASSWORD')}}</div>
                        </div>
                </div>
            </div>
            <!-- save profile -->
            <div class="display-flex-fe">
                <button class="btn disable w-100" :class="{'enable':isSaveBtnEnable}" @click="handleSavePassword">{{$t('SAVE')}}</button>
            </div> 
        </div>
    </div>
    <AccountIndex v-else>
        <div class="account-content__wrapper">
            <div class="account-content__title tc">{{$t(title)}}</div>
            <div>
                <div class="change-password__form-group">
                    <!--current password -->
                    <div v-if="!isNeverSetPassword" class="form-group">
                        <label class="form-label">{{$t('CURRENT_PASSWORD')}}</label>
                        <div class="form-group__container">
                            <input class="form-control" :type="currentPassword.showPassword ? 'text' : 'password'" v-model="currentPassword.inputValue" minlength="8" maxlength="20" v-on:keyup="currentInputPasswordEvent" :placeholder="$t('ENTER_CURRENT_PASSWORD')">
                            <div class="display-flex-center form-icon pointer" @click="currentPassword.showPassword = !currentPassword.showPassword">
                                <img v-if="currentPassword.showPassword" src="../../../static/images/icons/icon_close_pwd.png">
                                <img v-else src="../../../static/images/icons/icon_show_pwd.png">
                            </div>
                        </div>
                        <div class="form-tips">
                                <div v-if="currentPassword.isError" class="error">{{$t('FORGET_PASSWORD_ERR_MSG')}}</div>
                        </div>
                    </div>
                    <!--new password -->
                    <div class="form-group">
                        <label class="form-label">{{$t('NEW_PASSWORD')}}</label>
                        <div class="form-group__container">
                            <input class="form-control" :type="newPassword.showPassword ? 'text' : 'password'" v-model="newPassword.inputValue" minlength="8" maxlength="20" v-on:keyup="newInputPasswordEvent" :placeholder="$t('ENTER_NEW_PASSWORD')">
                            <div class="display-flex-center form-icon pointer" @click="newPassword.showPassword = !newPassword.showPassword">
                                <img v-if="newPassword.showPassword" src="../../../static/images/icons/icon_close_pwd.png">
                                <img v-else src="../../../static/images/icons/icon_show_pwd.png">
                            </div>
                        </div>
                        <div class="form-tips">
                            <div v-if="newPassword.isError" class="error">{{$t('FORGET_PASSWORD_ERR_MSG')}}</div>
                            <div v-else >{{$t('FORGET_PASSWORD_MSG')}}</div>
                        </div>
                    </div>
                    <!-- confirm password -->
                    <div class="form-group">
                        <label class="form-label">{{$t('CONFIRM_PASSWORD')}}</label>
                        <div class="form-group__container">
                            <input class="form-control" :type="confirmPassword.showPassword ? 'text' : 'password'" v-model="confirmPassword.inputValue" minlength="8" maxlength="20" v-on:keyup="confirmInputPasswordEvent" :placeholder="$t('RE_ENTER_NEW_PASSWORD')">
                            <div class="display-flex-center form-icon pointer" @click="confirmPassword.showPassword = !confirmPassword.showPassword">
                                <img v-if="confirmPassword.showPassword" src="../../../static/images/icons/icon_close_pwd.png">
                                <img v-else src="../../../static/images/icons/icon_show_pwd.png">
                            </div>
                        </div>
                        <div class="form-tips">
                            <div v-if="confirmPassword.isError" class="error">{{$t('RETYPE_YOUR_PASSWORD')}}</div>
                        </div>
                    </div>
                </div>
                <!-- save profile -->
                <div class="display-flex-fe">
                    <button class="btn disable " :class="{'enable':isSaveBtnEnable}" @click="handleSavePassword">{{$t('SAVE')}}</button>
                </div>
            </div>
        </div>
    </AccountIndex>
</template>

<script>
import MobileHeaderBack from '@/components/mobile/HeaderBack.vue'
import AccountIndex from '@/views/account/Index.vue'

import {mapGetters,mapActions} from 'vuex'
export default {
    components: {
        AccountIndex,
        MobileHeaderBack
    },
    data() {
        return {
            title: '',
            isSaveBtnEnable: false,
            isNeverSetPassword: true,

            currentPassword: {
                inputValue: '',
                showPassword: false,
                isError: false,  
            },
            newPassword: {
                inputValue: '',
                showPassword: false,
                isError: false,
            },
            confirmPassword: {
                inputValue: '',
                showPassword: false,
                isError: false,
            },
        }
    },
    created() {
      
    },

    beforeUnmount() {
        window.removeEventListener('keydown', this.listenKeypressEvent)
    },
    mounted() {
        window.addEventListener('keydown', this.listenKeypressEvent);
        this.init();
    },
     computed: {
        ...mapGetters([
            'isMobile',
            'currentLocale',
            'isLogin',
            "userInfo",
            "currentLanguageObj"
        ]),
    },
    methods: {
         ...mapActions([
            "getRetrieveUserInfo",
            "postUpdatePassword",
            "setUserData",
            "postSetPassword",
            "toastrChangeEvent",
             "commonVerifyLogin"
        ]), 

        init() {
             this.commonVerifyLogin();
             
            //otp (never set new password before)
            if (this.userInfo.loginMethod === 1) {
                this.isNeverSetPassword = true;
            } else {
                this.isNeverSetPassword = false;
                
            }
            this.title = this.$route.meta.name;
        },

       // get user info
        async getRetrieveUserInfoData() {
            let params = {
                token: this.userInfo.token,
                timeZone:this.$tools.getCurrentTimeZone()
            }
            const result = await this.getRetrieveUserInfo(params);

            this.setUserData(result.result.data);
            this.init();
        },

        //current password input event
        currentInputPasswordEvent() {
            let minLength = 8;
            let maxLength = 20;
            
            if (this.$tools.noSymbolVerification(this.currentPassword.inputValue) && this.currentPassword.inputValue.length >= minLength && this.currentPassword.inputValue.length <= maxLength) {
                this.currentPassword.isError = false;
            } else {
                this.currentPassword.isError = true;
            }

            this.verifyAllInput();
        },

        //new password input event
        newInputPasswordEvent() {
            let minLength = 8;
            let maxLength = 20;
            
            if (this.$tools.noSymbolVerification(this.newPassword.inputValue) && this.newPassword.inputValue.length >= minLength && this.newPassword.inputValue.length <= maxLength) {
                this.newPassword.isError = false;
            } else {
                this.newPassword.isError = true;
            }

            if (this.confirmPassword.inputValue !== this.newPassword.inputValue) {
               if (this.confirmPassword.inputValue.length > 0) {
                    this.confirmPassword.isError = true;
                }
            } else {
                this.confirmPassword.isError = false;
            }
            this.verifyAllInput();
        },

        //confirm password input event
        confirmInputPasswordEvent() {
            if (this.confirmPassword.inputValue !== this.newPassword.inputValue) {
                this.confirmPassword.isError = true;
            } else {
                this.confirmPassword.isError = false;
            }

            if (this.confirmPassword.inputValue.length === 0) {
                 this.confirmPassword.isError = true;
            }
            
            this.verifyAllInput();
        },

        //verify all input value
        verifyAllInput() {
            if (this.isNeverSetPassword) {
                this.currentPassword.inputValue = "t";
            } 
            if (this.currentPassword.inputValue.length > 0 && this.newPassword.inputValue.length > 0 && this.confirmPassword.inputValue.length > 0) {
               if (this.currentPassword.isError || this.newPassword.isError || this.confirmPassword.isError) {
                    this.isSaveBtnEnable = false;
                } else {
                    this.isSaveBtnEnable = true;
                }
            } else {
                this.isSaveBtnEnable = false;
            }
 
            
        },

        // update password
        async handleSavePassword() {
            let result;
            let toastMsg = "PASSWORD_CHANGED_SUCUESSFULLY";

            if (this.currentPassword.isError || this.newPassword.isError || this.confirmPassword.isError || !this.isSaveBtnEnable) {
                return
            }
           
            let params = {
                oldPassword: this.currentPassword.inputValue,
                newPassword: this.newPassword.inputValue,               
                confirmNewPassword: this.confirmPassword.inputValue,
                token: this.userInfo.token,
            }
            if (this.isNeverSetPassword) {
                let params = {
                    confirmPassword: this.confirmPassword.inputValue,
                    password: this.newPassword.inputValue,
                    token: this.userInfo.token,
                }
                result = await this.postSetPassword(params);
                toastMsg = "NEW_PASSWORD_SAVE_SUCUESSFULLY";
                
                this.getRetrieveUserInfoData();
            } else {
                result = await this.postUpdatePassword(params);
            }
             
            let paramsToastr={
                    isOpen:true,
                    msg:'',
                    type:''       
                }
            if (result.result.businessCode === 0) {
                paramsToastr.msg = toastMsg;
                //this.$root.toastrMsg = toastMsg;
                this.resetAllInput();
                if (this.isNeverSetPassword) {
                    this.isNeverSetPassword = false;
                    this.title = this.$route.meta.name;
                }
                
            } else {
                paramsToastr.msg = result.result.message;
                 this.$root.toastrMsg = result.result.message;
                 paramsToastr.type= 'info';
                 //this.$root.toastrType = 'info'
                   
            }
        
            this.toastrChangeEvent(paramsToastr);
            //this.$root.$refs.toastrRef.handleOpen(); 
        },

        resetAllInput() {
            this.currentPassword.inputValue = "";
            this.newPassword.inputValue = "";
            this.confirmPassword.inputValue = "";
        },

        listenKeypressEvent(e) {
            if (e.key === "Enter") {
               this.handleSavePassword();
            } 
        }
    },

}
</script>

<style>
.change-password__form-group{
    margin-bottom: 2.5625rem;
}
</style>